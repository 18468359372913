import styled from 'styled-components'

const Text = styled.p`
  width: ${({ width = 'auto' }) => width};
  height: ${({ height = 'auto' }) => height};
  padding: ${({ padding = '0' }) => padding};
  margin: ${({ margin = '0' }) => margin};
  ${({ textAlign }) => `text-align: ${textAlign}` || ''};
`

export default Text
